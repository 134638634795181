import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';

function HeroSection() {
    const scrollToContact = () => {
        const contactSection = document.getElementById('contact-section');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box
            sx={{
                height: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#fff',
                backgroundColor: "#664c40b6",
                margin: "1vh",
                padding: "0 10px", // Espaciado horizontal para reducir el riesgo de desbordamiento
                overflow: 'hidden'  // Asegurarse de que los elementos no se desborden
            }}
        >
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                style={{ width: "100%", maxWidth: "800px" }} // Asegura que el contenido tenga un ancho máximo
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '2rem', md: '3rem' }, // Ajusta el tamaño de la fuente según el tamaño de pantalla
                        wordWrap: 'break-word', // Rompe las palabras largas si es necesario
                    }}
                >
                    Transforma tus datos en decisiones inteligentes
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        marginY: 2,
                        fontSize: { xs: '1rem', md: '1.5rem' },
                        wordWrap: 'break-word'
                    }}
                >
                    Soluciones de Ciencia de Datos e Inteligencia Artificial para tu negocio
                </Typography>
                <Button
                    onClick={scrollToContact}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        mt: 4,
                        width: { xs: '100%', sm: 'auto' }, // Ajusta el ancho del botón en pantallas pequeñas
                        whiteSpace: 'nowrap', // Evita que el texto del botón se divida en varias líneas
                    }}
                >
                    Contáctanos
                </Button>
            </motion.div>
        </Box>
    );
}

export default HeroSection;


