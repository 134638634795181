// src/components/Footer.js
import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import title from '../assets/title.webp';

function Footer() {
    return (
        <Box sx={{ bgcolor: 'primary.main', p: 6, color: 'white',zIndex: 3 }} component="footer">
            
            <Container maxWidth="lg">
            <img src={title} alt="title" style={{ height: '80px' }} />
                <Typography variant="body1">
                AI Data Lord
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} mt={2}>
                    © {new Date().getFullYear()} AI Data Lord. Todos los derechos reservados.
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;